import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image/withIEPolyfill"

// Images
import logo_white from "../images/logo_white_xs.png"

const slider = ({
  sliderImage,
  sliderTitle,
  sliderSubtitle,
  objectPosition,
  button,
  link,
}) => {
  return (
    <>
      <div className="slider">
        <Img
          fluid={sliderImage}
          objectFit="cover"
          objectPosition={objectPosition}
          style={{ height: "100vh" }}
        />
      </div>
      <div className="slider__content">
        <div className="slider__contentLogo">
          <img src={logo_white} alt="logo" />
        </div>
        <h3>{sliderSubtitle}</h3>
        <h1>{sliderTitle}</h1>
        {button && (
          <div className="slider__button">
            <a target="_blank" href={button.link}>
              {button.text}
            </a>
          </div>
        )}
      </div>
      <div style={{ marginBottom: "90vh" }} />
    </>
  )
}

slider.defaultProps = {
  sliderTitle: `New Macau Casino`,
  sliderSubtitle: `Welcome`,
  objectPosition: `50% 50%`,
}

slider.propTypes = {
  sliderImage: PropTypes.object,
  sliderTitle: PropTypes.string,
  sliderSubtitle: PropTypes.string,
  objectPosition: PropTypes.string,
}

export default slider
